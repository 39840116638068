import { ID } from "../_metronic/helpers";

const REBUILD_API_URL = process.env.REACT_APP_URL_REBUILD_API
const API_URL = process.env.REACT_APP_API_URL_BE_SERVICE + "/api/v1"
const API_URL_REPORT = process.env.REACT_APP_API_URL_REPORT_SERVICE + "/api/v1"
const API_URL_CRON = process.env.REACT_APP_API_URL_CRON_SERVICE + "/api/v1"
const API_URL_TRANSFORMER = process.env.REACT_APP_API_URL_TRANSFORMER_SERVICE + "/api/v1"
const API_URL_GIS = process.env.REACT_APP_URL_GIS
const API_URL_GAMIFICATION = process.env.REACT_APP_URL_GAMIFICATION

// ## Auth
export const URL_USER_PROFILE_BY_TOKEN = API_URL_REPORT + "/employees/profile";

// Attendance 
export const URL_ATTENDANCE_COUNT_LIST = API_URL_REPORT + "/attendance/summary-table-view"
export const URL_ATTENDANCE_EMPLOYEE_EXPORT_URL = API_URL_REPORT + '/attendance/userwise/export'

// ## OTHER APIS
export const V_ROUTES = API_URL + "/vroutes";

export const DD_STATES = API_URL_REPORT + "/dd/states";
export const DD_DISTRICTS = API_URL_REPORT + "/dd/districts";
export const DD_FEEDERS = API_URL_REPORT + "/dd/feeders";
export const DD_TRANSFOMERS = API_URL_REPORT + "/dd/transformers";
export const DD_DESIGNATIONS = API_URL_REPORT + "/dd/employees/dd";

//export const URL_DD_TRANSFOMERS = API_URL_REPORT + "/dd/transformers";
export const DD_EMPLOYEES = API_URL_REPORT + "/dd/employees";
export const DD_V_ROUTES = API_URL_REPORT + "/dd/vroutes";
export const URL_DD_SERVICE_CENTER = API_URL + "/service_centers/service_dd/dd"
//export const URL_DD_STATES = API_URL_REPORT + "/dd/states";

// Auth 

// Consumers
export const URL_GET_ALL_CONSUMERS = API_URL + "/consumers";
export const URL_UPDATE_CONSUMER = (id: string) => API_URL + "/consumers/updateconsumer/" + id;
export const URL_GET_CONSUMER_INFO = API_URL_REPORT + "/consumer/consumer_no";
export const URL_GET_INDIRECT_CONSUMER_INFO = API_URL_REPORT + "/consumer/consumer_no";
export const URL_GET_CONSUMER_PAYMENT_HISTORY = API_URL_REPORT + "/historical/bill-payment";
export const URL_GET_CONSUMER_EVENTS_HISTORY = API_URL_REPORT + "/event/consumer/history";
export const URL_GET_CONSUMER_SUMMURY = API_URL_REPORT + '/consumer/summary';
export const URL_GET_ALL_CUSTOMERS_LIST = API_URL_REPORT + '/consumer/get-all';
export const URL_GET_ALL_CUSTOMERS_EXPORT_URL = API_URL_REPORT + '/consumer/get-all/excel';
export const URL_CONSUMER_FOLLOW_UP_FORM = API_URL + '/events/web';

// Employee
export const URL_EMPLOYEE_CRUD_BASE_URL = API_URL + '/employees_crud'
export const URL_EMPLOYEE_DETAILS_BY_ID = (ID: string) => URL_EMPLOYEE_CRUD_BASE_URL + '/employeesdetails/' + ID;
export const URL_FEEDER_LIST_BY_ID = (ID: string) => API_URL + '/feeders/mapping-list/' + ID;
export const URL_RPG_BY_ID = (ID: string) => API_URL + '/employees/get/mapping/' + ID;
export const URL_ADD_RPG_EMP =  API_URL + '/employees/add/mapping'
export const URL_REMOVE_RPG_EMP= API_URL + '/employees/mapping/remove';
export const URL_ADD_FEEDER_BY_ID = (ID: string) => API_URL + '/feeders/mapping-list/add-update/' + ID;
export const URL_REMOVE_FEEDER_BY_ID = (ID: string) => API_URL + '/feeders/mapping-list/remove-update/' + ID;
export const URL_GET_ALL_TRANSFORMER_BY_ID = (ID: string) => API_URL_TRANSFORMER + '/mapping/get/mapping/' + ID;
export const URL_ADD_TRANSFORMER_BY_ID = API_URL_TRANSFORMER + '/mapping/create';
export const URL_REMOVE_TRANSFORMER_BY_ID = API_URL_TRANSFORMER + '/mapping/remove/mapping';
export const URL_REBUILD_API = REBUILD_API_URL + "/zip-data"

// Regions
export const URL_REGIONS_BASE_URL = API_URL + "/regions"
export const URL_GET_ALL_REGIONS = URL_REGIONS_BASE_URL + "/getAll"
export const URL_CREATE_REGIONS = URL_REGIONS_BASE_URL + "/create"
export const URL_UPDATE_REGIONS = URL_REGIONS_BASE_URL + "/update"


// District
export const URL_ALL_DISTRICTS = API_URL + "/districts";

// SERVICE CENTER
export const BASEURL_GET_ALL_SERVICE_CENTERS = API_URL + '/service_centers';
export const URL_GET_SERVICE_CENTER_BY_ID = (id: string) => BASEURL_GET_ALL_SERVICE_CENTERS + "/" + id;

// Route -> Bookcode
export const URL_ASSIGN_ROUTES_TO_EMPLOYEE = API_URL + "/employees/add_vroutes";
export const URL_REMOVE_ROUTES_TO_EMPLOYEE = API_URL + "/employees/remove_vroutes";


// ## GROUPS APIS
export const BASEURL_GET_ALL_GROUPS = API_URL + "/groups";
export const URL_ADD_MEMBER_IN_GROUPS = BASEURL_GET_ALL_GROUPS + "/add_members";
export const URL_REMOVE_MEMBER_FROM_GROUPS = BASEURL_GET_ALL_GROUPS + "/remove_members";
export const URL_GET_MEMBERS_OF_GROUP = (id: string) => BASEURL_GET_ALL_GROUPS + "/members/" + id;
export const URL_GET_GROUPS_BY_MEMBER = (id: string) => BASEURL_GET_ALL_GROUPS + "/getGroupsByParticipantId/" + id;

// Filters APIS

// ## upload files
export const URL_UPLOAD_FILE = API_URL + '/uploads/upload';

// Disconnection API
export const URL_DISCONNECTION_BASE_URL = API_URL_REPORT + '/disconnection';
export const URL_DISCONNECTIONACTION_ACTION_REPORT = URL_DISCONNECTION_BASE_URL + '/reasons_summary'
export const URL_DISCONNECTIONACTION_REPORT = URL_DISCONNECTION_BASE_URL + '/report'
export const URL_DISCONNECTIONACTION_DASHBOARD_SUMMURY = URL_DISCONNECTION_BASE_URL + '/summary2'
export const URL_DISCONNECTIONACTION_DASHBOARD_FEEDER_LIST = URL_DISCONNECTION_BASE_URL + '/summary_list'
export const URL_DISCONNECTIONACTION_ADHOC_REPORT = URL_DISCONNECTION_BASE_URL + '/adhoc-list'


// Reconnection Apis
export const URL_RECONNECTION_BASE_URL = API_URL_REPORT + '/reconnection'
export const URL_RECONNECTION_DASHBOARD_SUMMURY = URL_RECONNECTION_BASE_URL + '/summary'
export const URL_RECONNECTION_DASHBOARD_FEEDERS_REPORT = URL_RECONNECTION_BASE_URL + '/summary_list'
export const URL_RECONNECTION_REPORT = URL_RECONNECTION_BASE_URL + '/report'

// orders 
export const URL_ORDERS_BASE_URL = API_URL_REPORT + '/order';
export const URL_ORDERS_SUMMARY = URL_ORDERS_BASE_URL + '/dashboard-summary';
export const URL_ORDERS_SUMMARY_LIST = URL_ORDERS_BASE_URL + '/summary-list';
export const URL_ORDERS_CONSUMERS_LIST = URL_ORDERS_BASE_URL + '/consumers-list';
export const URL_ORDERS_CONSUMERS_LIST_EXPORT = URL_ORDERS_CONSUMERS_LIST + '/export';
export const URL_ORDERS_ACTION_REPORT = URL_ORDERS_BASE_URL + '/action-report';

// KPI Report 
export const URL_EMPLOYEE_ALL = API_URL_REPORT + '/employees/all'
export const URL_KPI_REPORT = URL_EMPLOYEE_ALL + '/kpi'
export const URL_KPI_REPORT_EXPORT = URL_KPI_REPORT + '/export'
export const URL_KPI_RANK = URL_KPI_REPORT + '/rank'
export const URL_KPI_FEEDER = URL_EMPLOYEE_ALL + '/feeder/kpi'
export const URL_KPI_FEEDER_EXPORT = URL_KPI_FEEDER + '/export'
export const URL_KPI_SC = URL_EMPLOYEE_ALL + '/service-center/kpi'
export const URL_KPI_SC_EXPORT = URL_KPI_SC + '/export'
export const URL_KPI_DIST = URL_EMPLOYEE_ALL + '/district/kpi'
export const URL_KPI_DIST_EXPORT = URL_KPI_DIST + '/export'
export const URL_KPI_DSS = URL_EMPLOYEE_ALL + '/transformer/kpi'

// Observations
export const URL_OBESERVATIONS_REPORT = API_URL_REPORT + '/event/getall'

// Event -> Visits
export const URL_EVENTS_VISITS_BASE_URL = API_URL_REPORT + '/event/visit'
export const URL_EVENTS_VISITS_SUMMARY = URL_EVENTS_VISITS_BASE_URL + '/summary'
export const URL_EVENTS_VISITS_SUMMARY_LIST = URL_EVENTS_VISITS_BASE_URL + '/summary-list'
export const URL_EVENTS_VISITS_SUMMARY_LIST_EXPORT = URL_EVENTS_VISITS_SUMMARY_LIST + '/export'
export const URL_EVENTS_VISITS_REPORT = URL_EVENTS_VISITS_BASE_URL + '/report'
export const URL_EVENTS_VISITS_REPORT_EXPORT = URL_EVENTS_VISITS_BASE_URL + '/export'

// Action Report
export const URL_ACTION_REPORT = API_URL_REPORT + '/event/event-details'
export const URL_ACTION_REPORT_EXCEL_EXPORT = URL_ACTION_REPORT + '/export'

// BIll Delivery
export const URL_BILL_DELIVERY_DASHBOARD_SUMMARY = API_URL_REPORT + '/bill/summary'
export const URL_BILL_DELIVERY_DASHBOARD_SUMMARY_2 = API_URL_REPORT + '/event/summary/bill-delivery'
export const URL_BILL_DELIVERY_DASHBOARD_REPORT = API_URL_REPORT + '/event/summary-list/bill-delivery'
export const URL_BILL_DELIVERY_SUMMARY_LIST_EXPORT_URL = API_URL_REPORT + '/event/summary-list/bill-delivery/export'
export const URL_BILL_DELIVERY_REPORT_EXPORT_URL = API_URL_REPORT + '/event/reports/bill-delivery/export'
export const URL_BILL_DELIVERY_REPORT = API_URL_REPORT + '/event/reports/bill-delivery'

// Unpaid 
export const URL_UNPAID_BASE_URL = URL_ORDERS_BASE_URL + '/pmuc'
export const URL_UNPAID_DASHBOARD_SUMMARY = URL_UNPAID_BASE_URL + '/summary'
export const URL_UNPAID_DASHBOARD_SUMMARY_LIST = URL_UNPAID_BASE_URL + '/summary-list'
export const URL_UNPAID_DASHBOARD_SUMMARY_LIST_EXPORT = URL_UNPAID_BASE_URL + '/summary-list/export'
export const URL_UNPAID_REPORT = URL_UNPAID_BASE_URL + '/reports'
export const URL_UNPAID_REPORT_EXPORT = URL_UNPAID_BASE_URL + '/reports/export'


// Current Month Collection
export const URL_CMC_BASE_URL = API_URL_REPORT + '/collection/dashboard'
export const URL_CMC_DASH_SUMMARY_URL = URL_CMC_BASE_URL + '/summary'
export const URL_CMC_DASH_SUMMARY_LIST_URL = URL_CMC_BASE_URL + '/summary-list'
export const URL_CMC_DASH_SUMMARY_LIST_URL_EXPORT = URL_CMC_DASH_SUMMARY_LIST_URL + '/export'
export const URL_CMC_REPORT_URL = URL_CMC_BASE_URL + '/report'
export const URL_CMC_REPORT_EXPORT = URL_CMC_REPORT_URL + '/export'

// DUPLICATE BILLS
export const URL_DUPLICATE_BILLS_DASHBOARD_SUMMURY = ""


// METABASE
export const URL_METABASE = API_URL_REPORT + "/metabase/dashboard"

// QUESTIONS
export const URL_QUESTIONS = API_URL + "/questions/"


// Gamification API
// export const URL_GAMIFICATION=" https://gamification.orangecurrent.com/api"
export const URL_GAMIFICATION_REPORT = API_URL_GAMIFICATION + "/player-report"
export const URL_GAMIFICATION_SUMMARY = API_URL_GAMIFICATION + "/summury-report"
export const URL_OFFERS_COLUMNS = API_URL_GAMIFICATION + '/offer-ui'
export const URL_OFFERS_DATA = API_URL_GAMIFICATION + '/offer-summury'
export const URL_OFFERS_SUMMARY = API_URL_GAMIFICATION + '/offer-summary-report'


// GIS
export const URL_GIS_CONSUMERS = API_URL_GIS + '/bill_consumer.html'
export const URL_GIS_UNSERVED_CONSUMERS = API_URL_GIS + '/Unserved_Consumer.html'

// Profiling
// Employee
export const URL_EMPLOYEE_PROFILING_URL = API_URL_REPORT + '/employees/kpi-details';
export const URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_YEARLY_URL = API_URL_REPORT + '/employees/kpi-performance-history';
export const URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_CURRENT_MONTH_URL = API_URL_REPORT + '/employees/kpi-cm-history';
export const URL_EMPLOYEE_PROFILING_ACTION_HISTORY_MONTHLY_URL = API_URL_REPORT + '/event/history/user-id';
export const URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_CURRENT_MONTH_EXPORT_URL = URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_CURRENT_MONTH_URL + '/export';
export const URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_YEARLY_EXPORT_URL = URL_EMPLOYEE_PROFILING_PERFORMANCE_HISTORY_YEARLY_URL + '/export';
export const URL_EMPLOYEE_PROFILING_BEST_MONTH_URL = API_URL_REPORT + '/employees/kpi-best-month';
export const URL_EMPLOYEE_PROFILING_RR_URL = API_URL_GAMIFICATION + '/all-users-points';
export const URL_EMPLOYEE_PROFILING_RR_TARGET_URL = API_URL_GAMIFICATION + '/all-users-points-target';
export const URL_EMPLOYEE_PROFILING_RR_BONANZA_URL = API_URL_GAMIFICATION + '/bonanza-points-by-user';
export const URL_EMPLOYEE_PROFILING_RR_RANKS_URL = API_URL_GAMIFICATION + '/get-user-rank-points';
export const URL_EMPLOYEE_PROFILING_BUSINESS_CALENDAR_MONTHLY_SUMMARY_URL = API_URL + '/business/attendance/bym';

// Feeders
export const URL_FEEDER_PROFILING_URL = API_URL_REPORT + '/feeder/kpi-details';
export const URL_FEEDER_PROFILING_BEST_MONTH_DETAILS_URL = API_URL_REPORT + '/feeder/best-month-performance';
export const URL_FEEDER_PROFILING_ACTION_ANALYSIS_URL = API_URL_REPORT + '/feeder/kpi-events-details';
export const URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_YEARLY_URL = API_URL_REPORT + '/feeder/performance/feeder/kpi';
export const URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_YEARLY_EXPORT_URL = URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_YEARLY_URL + '/export'
export const URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_MONTHLY_URL = API_URL_REPORT + '/feeder/cm/performance/feeder/kpi';
export const URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_MONTHLY_EXPORT_URL = URL_FEEDER_PROFILING_PERFORMANCE_HISTORY_MONTHLY_URL + '/export'
export const URL_FEEDER_PROFILING_ACTION_HISTORY_MONTHLY_URL = API_URL_REPORT + '/feeder/kpi-events-list';

// Profiling End


// Notification
export const NOTICATION_REPORT_URL = API_URL_REPORT + '/notification/get-all';
export const SEND_NOTICATION_URL = API_URL + '/notifications/create';


// Roles & Designation
export const URL_EMPLOYEE_DESIGNATION_URL = API_URL + '/designation/getAll';
export const URL_EMPLOYEE_ROLES_URL = API_URL + '/ROLES/getAll';


// Punch-In/Out
export const URL_EMPLOYEE_ATTENDANCE_URL = API_URL + '/employees/status/add-track/web';
export const URL_EMPLOYEE_ATTENDANCE_STATUS_URL = (id: string) => API_URL_REPORT + '/attendance/get-by-userId' + "/" + id;

// Training Videos
export const TRAINING_VIDEOS_URL = API_URL + '/video_config/getall';

// METER READING
export const METER_READING_REPORT_URL = API_URL + '/meter_reading/getAll';
export const METER_READING_REPORT_EXPORT_URL = API_URL + '/meter_reading/getAll/excel';
export const METER_READING_SUMMARY_URL = API_URL + '/meter_reading/get/record-summary';
export const METER_READING_DUPLICATES_URL = (id: string) => API_URL + '/meter_reading/get/data' + "/" + id;
export const METER_READING_UPDATE_STATUS_URL = API_URL + '/meter_reading/change/status';
export const METER_READING_UPDATE_READINGS_URL = (id: string) => API_URL + '/meter_reading/update' + "/" + id;
export const METER_READING_GET_DATA_PG_URL = API_URL + '/meter_reading/get/pg';
export const METER_READING_GET_ALL_DATA_PG_URL = API_URL + '/meter_reading/get/pg/data';
export const METER_READING_AUDIT_URL = API_URL + '/meter_reading/get/history';
export const METER_READING_CRON_JOB_API_URL = API_URL_CRON + '/meter-reading/check-consumption';

// Change Request
export const CHANGE_REQUEST_REPORT_URL = API_URL + '/change_request/getAll';
export const CHANGE_REQUEST_CREATE_URL = API_URL + '/change_request/create';
export const CHANGE_REQUEST_GET_ALL_BY_ID = (id: string) => API_URL + '/change_request/get/data' + "/" + id;
export const CHANGE_REQUEST_UPDATE_URL = (id: string) => API_URL + '/change_request/update' + "/" + id;
export const CHANGE_REQUEST_GET_ALL_PG_DATA_URL = API_URL + '/change_request/getAllCr'
export const CHANGE_REQUEST_GET_ALL_PG_DATA_EXPORT_URL = API_URL + '/change_request/getAll/excel';
export const CHANGE_REQUEST_AUDIT_URL = API_URL + '/change_request/get/history';


// Adhoc DC
export const ADHOC_DC_REPORT_URL = API_URL_REPORT + '/order/adhoc/all';
export const ADHOC_DC_REPORT_EXPORT_URL = ADHOC_DC_REPORT_URL + '/export';
export const ADHOC_DC_REPORT_SUMMARY_URL = API_URL_REPORT + '/order/adhoc/summary';
export const ADHOC_DC_DUPLICATE_URL = API_URL_REPORT + '/order/adhoc/duplicate';
export const ADHOC_DC_UPDATE_URL = (id: string) => API_URL_REPORT + '/order/adhoc/dc-approval' + "/" + id;
export const ADHOC_DC_CHECK_URL = API_URL_REPORT + '/order/adhoc/check';


// Orders
export const CREATE_ORDER_FORM_URL = API_URL + '/orders';
export const ORDER_HISTORY_URL = API_URL_REPORT + '/order/history/consumer';


// RPG
